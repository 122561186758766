import graphqlClient from '../../../services/graphqlClient';
import getPlanrLinesQuery from '../graphql/getPlanrLinesQuery';
import searchPlanrSchedulesQuery from '../graphql/searchPlanrSchedulesQuery';
import * as R from 'ramda';
import createProductionCostMutation from '../graphql/createProductionCostMutation';
import importProductionCostPlanrDataMutation from '../graphql/importProductionCostPlanrDataMutation';
import productionCostBusesQuery from '../graphql/productionCostBusesQuery';
import releaseProductionCostEntryMutation from '../graphql/releaseProductionCostEntryMutation';
import productionCostProvidedValuesQuery from '../graphql/productionCostProvidedValuesQuery';
import productionCostEntryQuery from '../graphql/productionCostEntryQuery';
import getPlanrBusPartnersQuery from '../graphql/getPlanrBusPartners';
import getPlanrVehiclesQuery from '../graphql/getPlanrVehicles';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {
  PlanrBusPartner,
  PlanrLineSchedule,
  PlanrVehicle,
  ProductionCostFilterType
} from './types';
import getBusPartnerAndCountryCostEntryYearQuery from '../../PartnerCosts/graphql/getBusPartnerAndCountryCostEntryYearQuery';
import {
  receivedPlanrLines,
  productionCostCreationIsPending,
  productionCostWasCreated,
  importProductionCostDataFailed,
  receivedImportProductionCostData,
  receivedProductionCostBuses,
  receivedProductionCost,
  receivedPlanrBusPartners,
  receivedPlanrVehicles,
  receivedPlanrSchedules,
  receivedPartnerAndCountryDriverCostForVehicle
} from './slice';
import {Dispatch, SetStateAction} from 'react';
import {ProductionCostServerError} from '../components/types';

export const fetchPlanrLines = () => {
  return dispatch => {
    graphqlClient
      .query({
        query: getPlanrLinesQuery
      })
      .then(result => {
        const {
          data: {getPlanrLines}
        } = result;

        return dispatch(receivedPlanrLines(getPlanrLines));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const searchPlanrSchedules = (
  lineScheduleKey: number,
  lineSchedule: PlanrLineSchedule,
  setIsSchedulesLoading: Dispatch<SetStateAction<boolean>>
) => {
  return (dispatch, getState) => {
    const {productionCost} = getState();
    setIsSchedulesLoading(true);

    graphqlClient
      .query({
        query: searchPlanrSchedulesQuery,
        variables: {
          ...{
            startDate: formateProductionCostDate(productionCost.startDate),
            endDate: formateProductionCostDate(productionCost.endDate)
          },
          ...R.pick(
            ['lineUuid', 'scheduleStatus', 'scheduleType'],
            lineSchedule
          )
        }
      })
      .then(result => {
        const {
          data: {searchPlanrSchedules}
        } = result;

        setIsSchedulesLoading(false);

        return dispatch(
          receivedPlanrSchedules({
            lineScheduleKey,
            schedules: searchPlanrSchedules
          })
        );
      });
  };
};

// TODO: implement useCreateProductionCost hook similar to useCalculateProductionCost
export const createProductionCost = (
  filterType: ProductionCostFilterType,
  filteredIds: string[],
  callback: () => void
) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(productionCostCreationIsPending());
    const productionCost = state.productionCost;
    let planrBusPartnerIdFiltersData = [];
    let planrVehicleIdFiltersData = [];
    if (isNotNilOrEmpty(filteredIds)) {
      if (ProductionCostFilterType.PlanrBusPartners === filterType) {
        const planrBusPartners = state.productionCost?.planrBusPartners;
        planrBusPartnerIdFiltersData = getPlanrBusPartnerIdFiltersData(
          planrBusPartners,
          filteredIds
        );
      } else if (ProductionCostFilterType.PlanrVehicles === filterType) {
        const planrVehicles = state.productionCost?.planrVehicles;
        planrVehicleIdFiltersData = getPlanrVehicleIdFiltersData(
          planrVehicles,
          filteredIds
        );
      }
    }

    const data = R.pick(
      ['name', 'startDate', 'endDate', 'status', 'costYear'],
      productionCost
    );

    graphqlClient
      .mutate({
        mutation: createProductionCostMutation,
        variables: {
          ...data,
          startDate: formateProductionCostDate(data.startDate),
          endDate: formateProductionCostDate(data.endDate),
          planrBusPartnerIdFilters: planrBusPartnerIdFiltersData,
          planrVehicleIdFilters: planrVehicleIdFiltersData
        }
      })
      .then(result => {
        const {
          data: {createProductionCostEntry}
        } = result;
        dispatch(productionCostWasCreated(createProductionCostEntry.id));
        dispatch(importProductionCostData(callback));
      });
  };
};

const importProductionCostData = (callback: () => void) => {
  return (dispatch, getState) => {
    const state = getState();

    const productionCost = state.productionCost;

    const scheduleIds = R.pipe(
      R.pluck('schedules'),
      R.map(R.filter(x => x.selected)),
      R.map(R.pluck('uuid')),
      R.flatten
    )(productionCost.lineSchedules);

    graphqlClient
      .mutate({
        mutation: importProductionCostPlanrDataMutation,
        variables: {
          id: productionCost.id,
          scheduleIds
        },
        errorPolicy: 'all'
      })
      .then(result => {
        const {
          data: {importProductionCostPlanrData},
          errors
        } = result;

        if (errors) {
          dispatch(
            importProductionCostDataFailed(
              errors as any as ProductionCostServerError[]
            )
          );
        } else {
          dispatch(
            receivedImportProductionCostData(importProductionCostPlanrData)
          );
          callback();
        }
      })
      .catch(error => {
        const message = error.message ?? error;
        dispatch(
          importProductionCostDataFailed([
            {
              id: 'unknown_error',
              message
            } as any // TODO: improve when working on DIS2 global validation
          ])
        );
      });
  };
};

export const getProductionCostBuses = () => {
  return (dispatch, getState) => {
    const state = getState();

    const productionCost = state.productionCost;

    graphqlClient
      .query({
        query: productionCostBusesQuery,
        variables: {
          productionCostEntryId: productionCost.id
        }
      })
      .then(result => {
        const {
          data: {productionCostBuses}
        } = result;

        return dispatch(receivedProductionCostBuses(productionCostBuses));
      });
  };
};

export const fetchPartnerAndCountryDriverCost = (
  busPartnerId: string,
  countryId: string,
  productionCostYear: number,
  entryIds: string[]
) => {
  return dispatch => {
    graphqlClient
      .query({
        query: getBusPartnerAndCountryCostEntryYearQuery,
        variables: {busPartnerId, countryId, year: productionCostYear},
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      })
      .then(result => {
        const {
          data: {getBusPartnerCostEntryYear, countryCostEntry},
          errors
        } = result;

        // TODO: handle error in UI and show error message
        if (errors) {
          console.error('Error fetching BP and country driver cost: ', errors);
          return;
        }

        dispatch(
          receivedPartnerAndCountryDriverCostForVehicle({
            busPartnerId,
            countryId,
            productionCostYear,
            entryIds,
            busPartnerDriverCostEntry:
              getBusPartnerCostEntryYear?.driverCostEntry,
            countryDriverCostEntry: countryCostEntry?.driverCostEntry
          })
        );
      });
  };
};

export const releaseProductionCost = (releaseAndResetDataTable: () => void) => {
  return (dispatch, getState) => {
    const {productionCost} = getState();

    graphqlClient
      .mutate({
        mutation: releaseProductionCostEntryMutation,
        variables: {
          productionCostEntryId: productionCost.id
        }
      })
      .then(() => {
        releaseAndResetDataTable();
      });
  };
};

export const importProductionCostSchedules = (
  productionCostEntryId: string
) => {
  return dispatch => {
    graphqlClient
      .query({
        query: productionCostProvidedValuesQuery,
        variables: {
          productionCostEntryId
        },
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        const {
          data: {productionCostProvidedValues}
        } = result;

        dispatch(
          receivedImportProductionCostData(productionCostProvidedValues)
        );
      });
  };
};

export const getProductionCost = (productionCostEntryId: string) => {
  return dispatch => {
    graphqlClient
      .query({
        query: productionCostEntryQuery,
        variables: {
          productionCostEntryId
        },
        fetchPolicy: 'no-cache'
      })
      .then(result => {
        const {
          data: {productionCostEntry}
        } = result;
        dispatch(importProductionCostSchedules(productionCostEntryId));
        dispatch(receivedProductionCost(productionCostEntry));
      });
  };
};

const formateProductionCostDate = (dateString: string) => {
  const dateObj = new Date(dateString);

  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);

  return `${dateObj.getFullYear()}-${month}-${day}`;
};

export const fetchPlanrBusPartners = () => {
  return (dispatch, getState) => {
    const state = getState();
    const lineSchedules = state.productionCost?.lineSchedules;

    const scheduleIds = getScheduleIds(lineSchedules);

    graphqlClient
      .query({
        query: getPlanrBusPartnersQuery,
        variables: {
          scheduleIds
        }
      })
      .then(result => {
        const {
          data: {getPlanrBusPartners}
        } = result;

        return dispatch(receivedPlanrBusPartners(getPlanrBusPartners));
      });
  };
};

export const fetchPlanrVehicles = () => {
  return (dispatch, getState) => {
    const state = getState();
    const lineSchedules = state.productionCost?.lineSchedules;

    const scheduleIds = getScheduleIds(lineSchedules);

    graphqlClient
      .query({
        query: getPlanrVehiclesQuery,
        variables: {
          scheduleIds
        }
      })
      .then(result => {
        const {
          data: {getPlanrVehicles}
        } = result;

        return dispatch(receivedPlanrVehicles(getPlanrVehicles));
      });
  };
};

const getScheduleIds = (lineSchedules: PlanrLineSchedule[]) =>
  R.pipe(
    R.pluck('schedules'),
    R.map(R.filter(x => x.selected)),
    R.map(R.pluck('uuid')),
    R.flatten
  )(lineSchedules);

const getPlanrBusPartnerIdFiltersData = (
  planrBusPartners: PlanrBusPartner[],
  filteredPlanrBusPartnerIds: string[]
) => {
  const planrBusPartnerIdFiltersData = R.innerJoin(
    (busPartner, filteredBusPartnerId) =>
      busPartner.busPartnerBackendId === filteredBusPartnerId,
    planrBusPartners ?? [],
    filteredPlanrBusPartnerIds ?? []
  );
  return planrBusPartnerIdFiltersData.map(
    ({busPartnerBackendId, busPartnerName}) => ({
      planrBusPartnerBackendId: busPartnerBackendId,
      planrBusPartnerName: busPartnerName
    })
  );
};

const getPlanrVehicleIdFiltersData = (
  planrVehicles: PlanrVehicle[],
  filteredPlanrVehicleIds: string[]
) => {
  const planrVehicleIdFiltersData = R.innerJoin(
    (planrVehicle, filteredPlanrVehicleId) =>
      planrVehicle.id === filteredPlanrVehicleId,
    planrVehicles ?? [],
    filteredPlanrVehicleIds ?? []
  );
  return planrVehicleIdFiltersData.map(({id, name}) => ({
    planrVehicleId: id,
    planrVehicleName: name
  }));
};
