import {object, number, string} from 'yup';
import {Country} from 'Pages/Countries/store/types';
import {Partner} from 'Pages/Partners/store/types';
import DriverCostCalculationTypes from '../constants/driverCostCalculationTypes';
import {
  DriverCostCalculationType,
  ProductionCostBusProvidedValuesEntry,
  ProductionCostBusScheduleProvidedValueEntry,
  VehicleTypeCostEntries
} from '../store/types';

interface ValidateBusValuesContext {
  vehicle: ProductionCostBusProvidedValuesEntry;
  country?: Country;
  busPartner?: Partner;
  vehicleTypeCostEntry?: VehicleTypeCostEntries;
}

export const validateBusValues = (
  vehicle: ProductionCostBusProvidedValuesEntry,
  country?: Country,
  busPartner?: Partner,
  vehicleTypeCostEntry?: VehicleTypeCostEntries
) => {
  const validationOptions = {
    abortEarly: false,
    context: {vehicle, country, busPartner, vehicleTypeCostEntry}
  };

  const busTypeCostEntryId = string().test(
    'optionality',
    function (_, {options}) {
      const context = options.context as ValidateBusValuesContext;

      // not validate until bus partner is selected
      if (!this.parent.busPartnerId) return true;

      // not validate until vehicleTypeCostEntry is loaded
      const vehicleTypeCostEntry =
        context.vehicleTypeCostEntry as VehicleTypeCostEntries;
      if (!vehicleTypeCostEntry) return true;

      // if either of the values are set, return true
      const {countryBusTypeCostEntryId, busPartnerBusTypeCostEntryId} =
        context.vehicle ?? {};
      if (countryBusTypeCostEntryId || busPartnerBusTypeCostEntryId)
        return true;

      const {busPartnerBusTypeCostEntries, countryBusTypeCostEntries} =
        vehicleTypeCostEntry;

      const message =
        busPartnerBusTypeCostEntries.length === 0 &&
        countryBusTypeCostEntries.length === 0
          ? 'Vehicle types are not available for selected partner and year'
          : 'Vehicle type is required';

      return this.createError({message});
    }
  );

  const countryRulesCountryId = string()
    .required('Country rules are required')
    .test(
      'notMatchingCurrency',
      'Selected country has a different currency than the bus partner',
      (_, {options}) => {
        const {country, busPartner} = options.context ?? {};

        // Ensure the country is found and both currencies exist for comparison
        return !!country && country?.currency === busPartner?.currency;
      }
    );

  return object({
    busPartnerId: string().required('Partner name is required'),
    busTypeCostEntryId,
    countryRulesCountryId
  }).validateSync(vehicle, validationOptions);
};

export const validateBusScheduleValues = (
  vehicleEntry: ProductionCostBusScheduleProvidedValueEntry,
  driverCostCalculationType: DriverCostCalculationType
) => {
  const busUtilizationFactor = number()
    .nullable()
    .test('invalidRange', 'Value must be between 1 and 99 or blank', val => {
      if (!val) return true; // Allow blank values (null, undefined, '')

      // Check if val is a valid number and falls within the range
      return typeof val === 'number' && val >= 1 && val <= 99;
    });

  const flixStandardCostOverwrite = number()
    .nullable()
    .when('busUtilizationFactor', (busUtilizationFactor, schema) =>
      busUtilizationFactor[0]
        ? schema.notRequired() // Optional when busUtilizationFactor is provided
        : schema.test(
            'notAllowed',
            'Value requires vehicle utilization factor',
            value => !value // Must be undefined if busUtilizationFactor is not set
          )
    );

  const accommodationCostPerNight = number()
    .nullable()
    .when(
      'accommodationsPerWeek',
      (accommodationsPerWeek, schema) =>
        accommodationsPerWeek[0]
          ? schema.required('Price/accommodation is required')
          : schema.notRequired() // Optional when accommodationsPerWeek isn't provided
    );

  const numberOfCrewMembers = number()
    .nullable()
    .test(
      'optionality',
      'Field is required when crew member salary is provided',
      function (value) {
        const {crewMemberSalary} = this.parent;
        return crewMemberSalary ? value !== null && value !== undefined : true;
      }
    );

  const crewMemberSalary = number()
    .nullable()
    .test(
      'optionality',
      'Field is required when number of crew members is provided',
      function (value) {
        const {numberOfCrewMembers} = this.parent;
        return numberOfCrewMembers
          ? value !== null && value !== undefined
          : true;
      }
    );

  const driverScheduleHoursValidationSchema = object({
    busUtilizationFactor,
    flixStandardCostOverwrite,
    accommodationCostPerNight,
    countryDriverConceptTypeId: string().required(
      'Country driver concept type is required'
    )
  });

  const driverCostOverwriteValidationSchema = object({
    busUtilizationFactor,
    flixStandardCostOverwrite,
    accommodationCostPerNight,
    totalDriverCostOverwrite: number().required('Total driver cost is required')
  });

  const driverSalaryValidationSchema = object({
    busUtilizationFactor,
    flixStandardCostOverwrite,
    accommodationCostPerNight,
    driverSalary: number().required('Driver salary is required'),
    numberOfDrivers: number().required('Number of drivers is required'),
    numberOfCrewMembers,
    crewMemberSalary
  });

  const driverShiftsValidationSchema = object({
    busUtilizationFactor,
    flixStandardCostOverwrite,
    accommodationCostPerNight,
    dailyAllowance: number().required(
      'Total daily allowance/operating day is required'
    )
  });

  const validationOptions = {
    abortEarly: false
  };

  switch (driverCostCalculationType) {
    case DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value:
      return driverScheduleHoursValidationSchema.validateSync(
        vehicleEntry,
        validationOptions
      );
    case DriverCostCalculationTypes.DRIVER_COST_OVERWRITE.value:
      return driverCostOverwriteValidationSchema.validateSync(
        vehicleEntry,
        validationOptions
      );
    case DriverCostCalculationTypes.DRIVER_SALARY.value:
      return driverSalaryValidationSchema.validateSync(
        vehicleEntry,
        validationOptions
      );

    case DriverCostCalculationTypes.DRIVER_SHIFTS.value:
      return driverShiftsValidationSchema.validateSync(
        vehicleEntry,
        validationOptions
      );
  }
};
